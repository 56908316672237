



















































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';

import { AssetData, InspectionData, IPDEntry } from '@/store/asset/types';
import { namespace } from 'vuex-class';
import { AssetActions } from '@/store/asset/actions';
import util from '../../views/Asset/utils';
import ManholeTrimDialog from '../ManholeTrimDialog/ManholeTrimDialog.vue';
import { VideoActionCommands } from '../Video360/types';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const assetModule = namespace('asset');

@Component({
  components: {
    ManholeTrimDialog,
  },
})
export default class FlatVideo extends UserPermissionsMixin {
  @assetModule.Action(AssetActions.POST_MANUAL_IPD_ZERO_TIME) postManualIPDZeroTime;

  @assetModule.State('manualIPDZeroTimeLoading') manualIPDZeroTimeLoading: boolean;

  @Prop() videoSource: string | undefined;

  @Prop() videoState: number | undefined;

  @Prop({ default: true }) toggleHUD: boolean | undefined;

  @Prop() readonly asset!: AssetData;

  @Prop() readonly inspection!: InspectionData;

  @Prop() readonly ipd: IPDEntry[];

  @Prop() readonly timeToZeroMS: number;

  @PropSync('syncedVideoSpeed') videoSpeed: number;

  @Prop() readonly playbackSpeeds: number

  videoActionCommands = VideoActionCommands;

  headersCreated = false;

  lastInspectionGuid = '';

  scale = 1;

  videoDuration = 0;

  currentTime = 0;

  blockTimeChange = false;

  playing = false;

  reversePlaying = false;

  animationFrame = null;

  /**
   * @returns true if the user has the permission INSPECTION_VIEWER_TRIM_MANHOLE_VIDEO
   */
  get hasPermissionInspectionViewerManholeTrim(): boolean {
    return this.hasPermission(this.permissions.INSPECTION_VIEWER_TRIM_MANHOLE_VIDEO);
  }

  get inspectionsNoDupes(): InspectionData[] {
    const retArray = [];
    this.asset.inspections.forEach((insp) => {
      if (retArray.find((element) => element.guid === insp.guid) === undefined) {
        retArray.push(insp);
      }
    });
    return retArray;
  }

  get zeroTime(): number {
    return this.timeToZeroMS / 1000;
  }

  get isManholeAsset(): boolean {
    return this.asset?.type === 'Manhole';
  }

  mounted(): void {
    this.changeVideoTime(this.zeroTime);
    this.lastInspectionGuid = `flat-${this.inspection.guid}`;
    if (document.getElementsByClassName('video360').length !== 0) {
      this.asset.inspections.forEach((insp) => {
        const video = document.getElementById(`flat-${insp.guid}`) as HTMLVideoElement;
        video.addEventListener('loadeddata', () => {
          if (video.clientHeight > 0) {
            const resizeObserver = new ResizeObserver(() => {
              this.maintainAspectRatio(insp.guid);
            });

            resizeObserver.observe(this.$refs.flatVideo as any);
          }
        });

        video.addEventListener('timeupdate', () => {
          if (this.blockTimeChange) {
            this.blockTimeChange = false;
          } else {
            this.$emit('timeUpdate', video.currentTime);
            this.$emit(
              'timePercentUpdate',
              ((video.currentTime - this.zeroTime) / (video.duration - this.zeroTime)) * 100,
            );
          }
          this.currentTime = (video.currentTime - this.zeroTime) * 100;
        });

        video.onended = this.videoEnded;
        video.onplay = this.onPlaying;
        video.onplaying = this.onPlaying;
        video.ontimeupdate = this.onTimeUpdate;
      });

      this.onResize();
    }
    // This is a patch to get the video to load
    // setTimeout(() => {
    //   this.changeVideoTime(this.currentVideoTime);
    // }, 500);
  }

  getInspectionVideoSource(): string {
    return this.videoSource;
  }

  maintainAspectRatio(guid: string): void{
    const video = document.getElementById(`flat-${guid}`) as HTMLVideoElement;
    this.$emit('setVideoSize', this.videoDimensions(video));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  videoDimensions(video: any): any {
    const videoRatio = video.videoWidth / video.videoHeight;
    let width = video.offsetWidth; let
      height = video.offsetHeight;
    const elementRatio = width / height;
    if (elementRatio > videoRatio) {
      width = height * videoRatio;
    } else {
      height = width / videoRatio;
    }
    return {
      width,
      height,
    };
  }

  @Watch('videoSource')
  async onVideoSourceChange(value: string): Promise<void> {
    const videoPlayer = (document.getElementById(this.lastInspectionGuid) as HTMLVideoElement);
    if (videoPlayer == null) return;
    videoPlayer.pause();
    this.headersCreated = false;
    this.scale = 1;
    this.videoDuration = 0;
    this.currentTime = 0;
    this.blockTimeChange = false;
    this.playing = false;
    this.changeVideoTime(this.zeroTime);
    const video = document.getElementById(this.getSourceId()) as HTMLVideoElement;
    video.src = value;
    this.$emit('fullTimeUpdate', video.duration);
    this.onLoaded();
    this.lastInspectionGuid = `flat-${this.inspection.guid}`;
  }

  @Watch('videoState')
  onVideoStateChange(value: number): void {
    const video = document.getElementById(this.getSourceId()) as HTMLVideoElement;
    if (value === 1) {
      if (video.currentTime === video.duration) {
        this.changeVideoTime(this.zeroTime);
      }
      video.play();
    } else if (value === 2) {
      video.pause();
    }
    this.$emit('updatePauseState', 0);
  }

  @Watch('videoSpeed')
  onVideoSpeedChange(): void{
    if (this.playing) {
      this.emitCommand(VideoActionCommands.PAUSE_PLAY);
    }
    if (this.reversePlaying) {
      this.emitCommand(VideoActionCommands.REVERSE_PAUSE_PLAY);
    }
  }

  onSwitchView(timeUpdate: number): void{
    this.changeVideoTime(timeUpdate);
    this.setPlaybackSpeed(this.videoSpeed);
  }

  // eslint-disable-next-line class-methods-use-this
  pause() : void {
    const video = document.getElementById(this.getSourceId()) as HTMLVideoElement;
    video.pause();
  }

  onLoaded() : void {
    const video = document.getElementById(this.getSourceId()) as HTMLVideoElement;
    this.videoDuration = (video.duration - this.zeroTime) * 100;
    this.currentTime = (video.currentTime - this.zeroTime) * 100;
    video.playbackRate = this.videoSpeed;
    this.$emit('fullTimeUpdate', video.duration);
  }

  emitCommand(value: number): void {
    let cvt = 0;
    const video = document.getElementById(this.getSourceId()) as HTMLVideoElement;

    switch (value) {
      case VideoActionCommands.BACKWARD_10:
        cvt = video.currentTime - this.zeroTime;
        cvt -= 10;
        if (cvt < 0) {
          this.$emit('timeUpdate', this.zeroTime);
          this.changeVideoTime(this.zeroTime);
        } else {
          this.$emit('timeUpdate', cvt);
          this.changeVideoTime(cvt);
        }
        break;
      case VideoActionCommands.BACKWARD_STEP:
        cvt = video.currentTime - this.zeroTime;
        cvt -= 0.25;
        if (cvt < 0) {
          this.$emit('timeUpdate', this.zeroTime);
          this.changeVideoTime(this.zeroTime);
        } else {
          this.$emit('timeUpdate', cvt);
          this.changeVideoTime(cvt);
        }
        break;
      case VideoActionCommands.BACKWARD_SKIP:
        this.$emit('timeUpdate', this.zeroTime);
        this.changeVideoTime(this.zeroTime);
        break;
      case VideoActionCommands.PAUSE_PLAY:
        window.cancelAnimationFrame(this.animationFrame);
        video.onseeked = null;

        // Make sure we can never play and reverse at the same time
        this.reversePlaying = false;

        if (this.playing) {
          video.pause();
        } else {
          if (video.ended) {
            this.changeVideoTime(this.zeroTime);
          }
          video.play();
        }
        break;
      case VideoActionCommands.REVERSE_PAUSE_PLAY:
        window.cancelAnimationFrame(this.animationFrame);
        video.onseeked = null;
        this.reversePlaying = !this.reversePlaying;

        // Make sure we can never play and reverse at the same time
        this.playing = false;
        video.pause();

        // eslint-disable-next-line no-case-declarations
        const reverseSpeed = this.videoSpeed;

        if (this.reversePlaying) {
          const duration = video.seekable.end(0);
          let start = null;

          const step = (timestamp) => {
            if (!start) start = timestamp;
            const progress = timestamp - start;
            const time = (progress * reverseSpeed) / 1000;

            video.onseeked = () => {
              video.onseeked = null;
              window.requestAnimationFrame(step);
            };

            video.currentTime -= time;
            start = timestamp;

            // loop
            if (time > duration) {
              start = null;
            }

            // exit animation frame for begining of video
            if (video.currentTime === 0) {
              video.onseeked = null;
            }
          };
          this.animationFrame = window.requestAnimationFrame(step);
        }
        break;
      case VideoActionCommands.FORWARD_SKIP:
        this.$emit('timeUpdate', video.duration);
        this.changeVideoTime(video.duration);
        break;
      case VideoActionCommands.FORWARD_STEP:
        cvt = video.currentTime;
        cvt += 0.25;
        if (cvt > video.duration) {
          this.$emit('timeUpdate', video.duration);
          this.changeVideoTime(video.duration);
        } else {
          this.$emit('timeUpdate', cvt);
          this.changeVideoTime(cvt);
        }
        break;
      case VideoActionCommands.FORWARD_10:
        cvt = video.currentTime;
        cvt += 10;
        if (cvt > video.duration) {
          this.$emit('timeUpdate', video.duration);
          this.changeVideoTime(video.duration);
        } else {
          this.$emit('timeUpdate', cvt);
          this.changeVideoTime(cvt);
        }
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onChangeScrollBar(): void {
    const video = document.getElementById(this.getSourceId()) as HTMLVideoElement;
    const slider = document.getElementById('slider') as HTMLInputElement;
    video.currentTime = (parseInt(slider.getAttribute('value') as string, 10) / 100) + this.zeroTime;
  }

  changeVideoTime(value: number): void {
    const video = document.getElementById(this.getSourceId()) as HTMLVideoElement;
    if (video) {
      video.pause();
      video.currentTime = value;
      this.$emit('timePercentUpdate', ((value - this.zeroTime) / (video.duration - this.zeroTime)) * 100);
      this.$emit('timeUpdate', value);
      this.currentTime = (value - this.zeroTime) * 100;
    }
  }

  setPlaybackSpeed(speed: number): void {
    this.inspectionsNoDupes.forEach((insp) => {
      const video = (document.getElementById(this.getVideoId(insp.guid)) as HTMLVideoElement);
      video.playbackRate = speed;
    });
    this.videoSpeed = speed;
  }

  changeVideoTimeNoPercent(value: number): void {
    const video = document.getElementById(this.getSourceId()) as HTMLVideoElement;
    if (video) {
      video.pause();
      this.blockTimeChange = true;
      video.currentTime = value;
      this.$emit('timeUpdate', value);
      this.currentTime = (value - this.zeroTime) * 100;
    }
  }

  videoEnded(): void {
    const video = document.getElementById(this.getSourceId()) as HTMLVideoElement;
    this.$emit('timeUpdate', video.duration);
    this.onEnded();
  }

  onResize(): void {
    const div = document.getElementById('scenediv') as HTMLElement;
    const ascene = document.getElementById('ascene') as HTMLElement;
    if (!ascene?.style?.width
      || !ascene?.style?.height
      || !ascene?.childNodes?.length
      || ascene?.childNodes?.length === 0
      || !div) {
      return;
    }
    ascene.style.width = `${window
      .getComputedStyle(div, null)
      .getPropertyValue('width')}px`;
    ascene.style.height = `${window
      .getComputedStyle(div, null)
      .getPropertyValue('height')}px`;
    const height = parseInt(
      window.getComputedStyle(div, null).getPropertyValue('height'),
      10,
    );
    (ascene.childNodes[0] as HTMLCanvasElement).width = parseInt(window
      .getComputedStyle(div, null)
      .getPropertyValue('width'), 10);
    (ascene.childNodes[0] as HTMLCanvasElement).height = parseInt(window
      .getComputedStyle(div, null)
      .getPropertyValue('height'), 10);
    (ascene.childNodes[0] as HTMLCanvasElement).style.width = `${window
      .getComputedStyle(div, null)
      .getPropertyValue('width')}px`;
    (ascene.childNodes[0] as HTMLCanvasElement).style.height = `${window
      .getComputedStyle(div, null)
      .getPropertyValue('height')}px`;
    this.$emit('changeHeight', height);
  }

  onPlaying(): void {
    this.playing = true;
  }

  onPause(): void{
    this.playing = false;
  }

  onEnded(): void {
    this.playing = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onTimeUpdate(evt: any): void {
    // Pause the video if we get to the begining and are reversing
    if (evt.srcElement.currentTime <= 0 && this.reversePlaying) {
      this.emitCommand(VideoActionCommands.REVERSE_PAUSE_PLAY);
    }
  }

  timeChange(distance: number, time: number): void {
    this.changeVideoTime(util.getClosestTime(this.inspection.payoutIPD, distance, time) / 1000);
  }

  getVideoId(inspGuid: string): string {
    return `flat-${inspGuid}`;
  }

  getSourceId(): string {
    return `flat-${this.inspection.guid}`;
  }
}
