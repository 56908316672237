/* eslint-disable @typescript-eslint/no-explicit-any */
import { Defect } from './components/Maps/types';

// eslint-disable-next-line no-shadow
export enum ReportType {
    MACP = 0,
    Observation = 1,
    Other
}
export interface Location {
    latitude: number;
    longitude: number;
    source?: string;
}

export interface LocationLS {
    upstream: Location;
    downstream: Location;
    hasFlowData: number;
    source?: string;
}

export interface CodingStatus {
    description: string;
    ord: number;
    shortName: string;
}

export interface Inspection {
    date: string;
    defects?: Defect[];
    defectScore: number;
    omGrade?: number | null;
    structGrade?: number | null;
    inspectionGuid?: string;
    level: string;
    codingStatus?: CodingStatus;
    platform?: string;
    pipeSize?: string;
    material?: string;
    street?: string;
    sewerUse?: string;
    omQuick?: string;
    lengthSurveyed?: number;
    isImperial?: boolean;
    direction?: string;
}

export interface CodingData {
    id: number;
    code?: string;
    step?: boolean;
    joint?: boolean;
    clockTo?: number;
    oMGrade?: number;
    remarks?: string;
    vCRTime?: string;
    category?: string;
    distance?: number;
    component?: string;
    continuous?: string;
    clockAtFrom?: unknown;
    structGrade?: unknown;
    subCategory?: string;
    isRearCamera?: boolean;
    valuePercent?: unknown;
    videoCounter?: number;
    conditionName?: string;
    // eslint-disable-next-line camelcase
    inspection_ID?: number;
    v360CamPanAngle?: unknown;
    v360CamTiltAngle?: number;
    v360CamZoomAngle?: number;
    value1stDimension?: unknown;
    value2ndDimension?: unknown;
}

export interface Lateral {
    guid: string;
    upstream: Location;
    downstream: Location;
    assetGuid: string;
    coding: CodingData;
}

export interface OverallScoringDetail {
    omIndex?: string;
    omQuick?: string;
    omRating?: string;
    overallIndex?: string;
    overallQuick?: string;
    overallRating?: string;
    structuralIndex?: string;
    structuralQuick?: string;
    structuralRating?: string;
}
export interface Assignment {
    status?: string;
    completionDate?: string;
    taskType?: string;
}
export interface AssetRef {
    name: string;
    guid: string;
    priority?: number;
    status?: string;
    type: string;
    inspectionDate?: string;
    taskResult?: string;
    latitude?: number;
    longitude?: number;
    location?: Location[];
    upstream?: string;
    downstream?: string;
    attributes?: string;
    inspections?: Inspection[];
    laterals?: Lateral[];
    overallScoring?: OverallScoringDetail;
    hasCustomerDeliverables: boolean;
    visible?: boolean;
    owner?: string;
    sewerUse?: string;
    assetType?: string;
    validation?: string;
    photos?: unknown[];
    projectGuid?: string;
    assignments?: Assignment[];
    platform?: string;
    customerWorkOrder?: string;
    batchNumber?: string;
    gisLength?: number;
    lengthSurveyed?: number;
}

export interface AssetData {
    guid: string;
    name: string;
    score: number;
    type: string;
    visible: boolean;
    hasCustomerDeliverables?: boolean;
    location?: Location[] | LocationLS[];
    status?: string;
    taskResult?: string;
    codingStatus?: string;
    overallQuick?: string;
    overallIndex?: string;
    overallRating?: string;
    structuralQuick?: string;
    structuralIndex?: string;
    structuralRating?: string;
    omQuick?: string;
    omIndex?: string;
    omRating?: string;
    platform?: string;
    customerWorkOrder?: string;
    batchNumber?: string;
    instDate?: string;
    owner?: string;
    sewerUse?: string;
    comments?: string;
    assetId?: string;
    upstreamId?: string;
    downstreamId?: string;
    street?: string;
    city?: string;
    basin?: string;
    pipeSize?: string;
    pipeMaterial?: string;
    lastInspected?: string;
    gisLength?: number;
    lengthSurveyed?: number;
}

export interface MatchFilter {
    header: string;
    type: string;
    value: string | string[];
    method: string;
    currentOptionChanges: string[] & number[];
    options: string[] & number[];
    tempValue: string;
    hasFilterChanges?: boolean;
    conversionFunction?: any;
}

export interface BlankMatchFilter {
    [key: string]: boolean;
}

export interface AdvancedFilter {
    key?: number;
    text: string;
    comparison?: string;
    comparisonValue?: string;
    selectedOptions?: string[];
}

export interface FilterPair {
    section: string;
    value: string;
}

// This interface represents all possible asset filter options available
// and will be passed from the MapGIS and AssetTable components
// to the Project component. The Project component then uses the filter
// to modify the visibility of assets.
export interface AssetFilter {
    // list of guids selected by asset map (to handle view extents filter and selection box)
    selectedAssets: string[];
    type?: string[];
    collectionStatus?: string[];
    reportingStatus?: string[];
    dateInspected?: string[];
    customFromDate?: string;
    customToDate?: string;
    score: number[];
    robot?: string[];
    status: string[];
    matchFilters?: MatchFilter[];
    advancedFilters?: AdvancedFilter[];
    hiddenFilters?: FilterPair[],
    omQuick?: string[],
    structuralQuick?: string[],
}

export interface AssetGraphic {
    guid: string;
    name: string;
    score: number;
    type: string;
    visible: boolean;
    location?: Location[] & LocationLS[];
    taskResult?: string;
    inspectionDate?: string;
}

export interface NavItem {
    title: string;
    icon: string;
    value: string;
    expand: boolean;
    link: { name?: string, params?: any } | string;
    permission?: boolean;
    hide?: string[];
    paramsRequired?: boolean;
}
